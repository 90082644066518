import { useEffect, useState, useContext } from 'react';

import moment from 'moment';

import { isMobileDevice } from "../../utils";

import AppointmentsService from "../../services/appointmentsService";
import Appointment, { PatientStatus } from '../../models/appointment';
import DateUtils from '../../../src/shared/src/utils/dateUtils';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { GlobalContext } from '../../GlobalContext';
import { t } from 'i18next';


const WaitingRoomPage: React.FC = () => {

    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const [commingAppointments, setCommingAppointments] = useState<Appointment[]>([]);
    const [delayedAppointments, setDelayedAppointments] = useState<Appointment[]>([]);
    const [treatedAppointments, setTreatedAppointments] = useState<Appointment[]>([]);
    const [waitingRoomAppointments, setWaitingRoomAppointments] = useState<Appointment[]>([]);

    const [dateTimeString, setDateTimeString] = useState<string>("");

    type tabTypes = "waitingRoom" | "todaysAppointments" | "delayedAppointments" | "treatedAppointments";
    const [activeTab, setActiveTab] = useState<tabTypes>("waitingRoom");

    const {currentClient} = useContext(GlobalContext);
    const {currentUser} = useContext(GlobalContext);
    const {calendars} = useContext(GlobalContext);
    const {rooms} = useContext(GlobalContext);
    const { setIsAppointmentDialogVisible, setSelectedAppointment } = useContext(GlobalContext);

    useEffect(() => {

        if (currentClient && currentUser && currentUser.locationId) {

            console.log("subscribe dashboard listeners");

            const fromDate = moment().startOf("day").toDate();
            const toDate = moment().endOf("day").toDate();
            const unsubscribeAppointments = AppointmentsService.startListenForAppointments(fromDate, toDate, currentClient.id, currentUser.locationId, (result) => handleAppointmentsChange(result));

            return () => {
                console.log("unsubscribe dashboard listeners");
                unsubscribeAppointments();
            }
        }

    }, [currentClient]);

    useEffect(() => {

        const interval = setInterval(() => {
            updateData();
        }, 1000 * 30);

        return () => clearInterval(interval);
    }, [appointments]);

    useEffect(() => {
        const interval = setInterval(() => {
            updateDateTimeString();
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    function handleAppointmentsChange(result: Appointment[]) {
        setAppointments(result);

        updateData(result);
    }

    function updateDateTimeString() {
        const now = new Date();
        const weekday = now.toLocaleString("default", { weekday: "long" })
        const _dateTimeString = isMobileDevice() ? `${DateUtils.getDateString(now)} - ${DateUtils.getTimeString(now)}` : `${weekday} ${DateUtils.getDateString(now)} - ${DateUtils.getTimeString(now)}`
        setDateTimeString(_dateTimeString);
    }

    function updateData(paramAppointments?: Appointment[]) {

        console.log("Updating dashboard data " + appointments.length);

        updateDateTimeString();

        let _appointments = paramAppointments ? paramAppointments : appointments;

        _appointments = _appointments.filter(appointment => appointment.calendarItemType !== "absence" && !appointment.isVirtual());

        const now = Date.now();
        const oneHourAgo = moment(now).subtract(1, "hours").toDate().getTime();

        const _commingAppointments = _appointments.filter(appointment => appointment.start.getTime() >= oneHourAgo && appointment.patientStatus === PatientStatus.none);
        setCommingAppointments(_commingAppointments);

        const _delayedAppointments = _appointments.filter(appointment => appointment.start.getTime() < oneHourAgo && appointment.patientStatus === PatientStatus.none);
        setDelayedAppointments(_delayedAppointments);

        const _waitingRoomAppointments = _appointments.filter((appointment) => appointment.patientStatus === PatientStatus.inWaitingRoom);
        setWaitingRoomAppointments(_waitingRoomAppointments);

        const _treatedAppointments = _appointments.filter(appointment => appointment.patientStatus === PatientStatus.treated);
        setTreatedAppointments(_treatedAppointments);


    }

    function getRessourceName(ressourceId: string): string {
        if (calendars) {
            for (let index = 0; index < calendars.length; index++) {
                const calendar = calendars[index];
                if (calendar.id === ressourceId) {
                    return calendar.getShortName();
                }
            }
        }

        return "";
    }

    function getAppointmentStatusClassName(appointment: Appointment) {
        const now = new Date();
        const startDate = DateUtils.getDate(appointment.start);
        let className = "";

        if (startDate && startDate.getTime() < now.getTime()) {

            switch (appointment.patientStatus) {
                case PatientStatus.none:
                    className = "kt-warning-color";
                    break;

                case PatientStatus.treated:
                    className = "kt-line-through";
                    break;

                default:
                    break;
            }

        }

        return className;
    }

    function setPatientStatus(e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>, appointment: Appointment, newStatus: PatientStatus) {
        e.preventDefault();
        e.stopPropagation();
        if (currentUser) {
            AppointmentsService.setNewPatientStatus(appointment.id, currentClient, currentUser.locationId, newStatus);
        }
    }

    function preventRowClick(e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();
    }

    function handleRoomChange(newRoomId: any, appointment: Appointment) {
        if (newRoomId && currentUser) {
            AppointmentsService.updateAppointmentProperty(appointment.id, currentClient.id, currentUser.locationId, "roomId", newRoomId);
        }
    }


    async function onAppointmentClick(clickEvent?, appointmentId?: string) {

        // check if user clicked on a public holiday or similar date
        if (clickEvent && clickEvent.event.allDay) {
            return;
        }


        let _appointmentId = "";

        if (clickEvent && clickEvent.event && clickEvent.event.id) {
            _appointmentId = clickEvent.event.id;
        } else if (appointmentId) {
            _appointmentId = appointmentId
        }

        if (_appointmentId && currentUser) {
            const appointment = await AppointmentsService.getAppointment(_appointmentId, currentUser.clientId, currentUser.locationId);
            if (appointment) {
                setSelectedAppointment(appointment.clone());

                setIsAppointmentDialogVisible(true);
            }
        }

    }


    function renderTodaysAppointments() {

        if (commingAppointments.length === 0) {
            return <div>{t("waitingRoomPage.noAppointmentsToday")}</div>
        }

        return <table className="kt-waiting-room">
            <tbody>
                {commingAppointments.map((appointment) =>
                    <tr key={appointment.id} className={getAppointmentStatusClassName(appointment)} onClick={() => onAppointmentClick(null, appointment.id)}>
                        <td>{DateUtils.getTimeString(appointment.start)}</td>
                        <td className="kt-hide-on-mobile">{appointment.title}</td>
                        <td>{appointment.visitMotive.name}<b className="kt-hide-on-mobile">{appointment.comments ? " - " + appointment.comments : ""}</b></td>
                        <td className="kt-hide-on-mobile">{appointment.patient.getMobileOrPhoneNumber()}</td>
                        <td>{getRessourceName(appointment.resourceId)}</td>
                        <td onClick={(e) => preventRowClick(e)}>
                            <Select
                                name="appointment.roomId"
                                id="room-select"
                                value={appointment.roomId}
                                onChange={(e) => handleRoomChange(e.target.value, appointment)}
                            >
                                {rooms.map(room => <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>)}
                            </Select>
                        </td>
                        {appointment.patientStatus === PatientStatus.inWaitingRoom ?
                            <td className="kt-btn-table-column"></td>
                            :
                            <td className="kt-btn-table-column" onClick={(e) => setPatientStatus(e, appointment, PatientStatus.inWaitingRoom)}><i className="far fa-hourglass"></i></td>
                        }

                    </tr>
                )}
            </tbody>
        </table>
    }

    function renderDelayedAppointments() {

        if (delayedAppointments.length === 0) {
            return <div>{t("waitingRoomPage.noMissedAppointmentsToday")}</div>
        }

        return <table className="kt-waiting-room">
            <tbody>
                {delayedAppointments.map((appointment) =>
                    <tr key={appointment.id} onClick={() => onAppointmentClick(null, appointment.id)}>
                        <td>{DateUtils.getTimeString(appointment.start)}</td>
                        <td className="kt-hide-on-mobile">{appointment.title}</td>
                        <td>{appointment.visitMotive.name}</td>
                        <td className="kt-hide-on-mobile">{appointment.patient.getMobileOrPhoneNumber()}</td>
                        <td>{getRessourceName(appointment.resourceId)}</td>
                        <td onClick={(e) => preventRowClick(e)}>
                            <Select
                                name="appointment.roomId"
                                id="room-select"
                                value={appointment.roomId}
                                onChange={(e) => handleRoomChange(e.target.value, appointment)}
                            >
                                {rooms.map(room => <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>)}
                            </Select>
                        </td>
                        {appointment.patientStatus === PatientStatus.inWaitingRoom ?
                            <td className="kt-btn-table-column"></td>
                            :
                            <td className="kt-btn-table-column" onClick={(e) => setPatientStatus(e, appointment, PatientStatus.inWaitingRoom)}><i className="far fa-hourglass"></i></td>
                        }
                    </tr>
                )}
            </tbody>
        </table>
    }

    function renderTreatedAppointments() {

        if (treatedAppointments.length === 0) {
            return <div>{t("waitingRoomPage.noPatientsTreatedToday")}</div>
        }

        return <table className="kt-waiting-room">
            <tbody>
                {treatedAppointments.map((appointment) =>
                    <tr key={appointment.id} onClick={() => onAppointmentClick(null, appointment.id)}>
                        <td>{DateUtils.getTimeString(appointment.start)}</td>
                        <td className="kt-hide-on-mobile">{appointment.title}</td>
                        <td>{appointment.visitMotive.name}<b className="kt-hide-on-mobile">{appointment.comments ? " - " + appointment.comments : ""}</b></td>
                        <td>{getRessourceName(appointment.resourceId)}</td>
                        <td onClick={(e) => preventRowClick(e)}>
                            <Select
                                name="appointment.roomId"
                                id="room-select"
                                value={appointment.roomId}
                                onChange={(e) => handleRoomChange(e.target.value, appointment)}
                            >
                                {rooms.map(room => <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>)}
                            </Select>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    }

    function getWaitingRoomDurationString(appointment: Appointment, now: Date): string {
        const duration = appointment.start.getTime() - now.getTime();

        if (duration < 0) {
            return (isMobileDevice() ? "" : `${t("waitingRoomPage.since")} `) + DateUtils.getDurationString(appointment.start, now, isMobileDevice());
        } else if (duration > 0) {
            return `${t("waitingRoomPage.in")} ${DateUtils.getDurationString(now, appointment.start, isMobileDevice())}`;
        } else {
            return `${t("waitingRoomPage.now")}`;
        }

    }

    function renderWaitingRoom() {

        const now = new Date();

        if (waitingRoomAppointments.length === 0) {
            return <div>{t("waitingRoomPage.waitingRoomIsEmpty")}</div>
        }

        return <table className="kt-waiting-room">
            <tbody>
                {waitingRoomAppointments.map((appointment) =>
                    <tr key={appointment.id} onClick={() => onAppointmentClick(null, appointment.id)}>
                        <td>{getWaitingRoomDurationString(appointment, now)}</td>
                        <td className="kt-hide-on-mobile">{appointment.title}</td>
                        <td>{appointment.visitMotive.name}<b className="kt-hide-on-mobile">{appointment.comments ? " - " + appointment.comments : ""}</b></td>
                        <td>{getRessourceName(appointment.resourceId)}</td>
                        <td onClick={(e) => preventRowClick(e)}>
                            <Select
                                name="appointment.roomId"
                                id="room-select"
                                value={appointment.roomId}
                                onChange={(e) => handleRoomChange(e.target.value, appointment)}
                            >
                                {rooms.map(room => <MenuItem key={room.id} value={room.id}>{room.name}</MenuItem>)}
                            </Select>
                        </td>

                        <td className="kt-btn-table-column" onClick={(e) => setPatientStatus(e, appointment, PatientStatus.treated)}><i className="fas fa-check"></i></td>

                    </tr>
                )}
            </tbody>
        </table>
    }


    return (

        <>
            <div className="kt-dashboard-page kt-grey-page">
                <h2><span className='kt-uppercase'>{t("waitingRoomPage.title")}</span> - {dateTimeString}</h2>

                <div className="card-container">

                    <div className="card full">
                        <div className="card-head">
                            {activeTab === "waitingRoom" && <span className='kt-uppercase'><i className="fal fa-hourglass" aria-hidden="true"></i>{`${t("waitingRoomPage.patientsInWaitingRoom")} ${waitingRoomAppointments.length}`}</span>}
                            {activeTab === "todaysAppointments" && <span className='kt-uppercase'><i className="fal fa-calendar-alt" aria-hidden="true"></i>{`${t("waitingRoomPage.commingAppointments")} ${commingAppointments.length}`}</span>}
                            {activeTab === "treatedAppointments" && <span className='kt-uppercase'><i className="fal fa-user-check" aria-hidden="true"></i>{`${t("waitingRoomPage.treatedPatients")} ${treatedAppointments.length}`}</span>}
                            {activeTab === "delayedAppointments" && <span className='kt-uppercase'><i className="fal fa-ban" aria-hidden="true"></i>{`${t("waitingRoomPage.missedAppointments")} ${delayedAppointments.length}`}</span>}
                            <div className="kt-button-wrapper">
                                <div><button className={activeTab === "waitingRoom" ? "kt-btn kt-btn-tiny kt-btn-selected" : "kt-btn kt-btn-tiny"} onClick={() => setActiveTab("waitingRoom")}>{t("waitingRoomPage.waitingRoom")}</button></div>
                                <div><button className={activeTab === "todaysAppointments" ? "kt-btn kt-btn-tiny kt-btn-selected" : "kt-btn kt-btn-tiny"} onClick={() => setActiveTab("todaysAppointments")}>{t("waitingRoomPage.upcoming")}</button></div>
                                <div><button className={activeTab === "treatedAppointments" ? "kt-btn kt-btn-tiny kt-btn-selected" : "kt-btn kt-btn-tiny"} onClick={() => setActiveTab("treatedAppointments")}>{t("waitingRoomPage.treated")}</button></div>
                                <div><button className={activeTab === "delayedAppointments" ? "kt-btn kt-btn-tiny kt-btn-selected" : "kt-btn kt-btn-tiny"} onClick={() => setActiveTab("delayedAppointments")}>{t("waitingRoomPage.missed")}</button></div>
                            </div>
                        </div>

                        {activeTab === "waitingRoom" && <div className="card-body">
                            {renderWaitingRoom()}
                        </div>}

                        {activeTab === "todaysAppointments" && <div className="card-body">
                            {renderTodaysAppointments()}
                        </div>}

                        {activeTab === "treatedAppointments" && <div className="card-body">
                            {renderTreatedAppointments()}
                        </div>}

                        {activeTab === "delayedAppointments" && <div className="card-body">
                            {renderDelayedAppointments()}
                        </div>}

                    </div>

                </div>

            </div>
        </>

    );
}

export default WaitingRoomPage;
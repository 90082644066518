import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import { t } from "i18next";

interface Props {
    customMessage?: string | JSX.Element
}

const LoadingPage:React.FC<Props> = ({customMessage}) => {

    return (
        <div className="kt-loading-page">

            <div className="kt-loading-page-div"  style={{minWidth: "200px"}}>
                <h1>Pickadoc</h1>

                <img src="/images/logo.svg" alt="logo" className='kt-logo' />

                <div className="kt-grid" style={{marginTop: "20px"}}>
                    <div>{customMessage ? customMessage : t("loadingApplication")}</div>
                    <LinearProgress />
                </div>
            </div>
        </div>
    )
}

export default LoadingPage;
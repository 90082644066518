import React from 'react';
import i18next, { t } from 'i18next';
import CountryFlag from './countryFlag';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


const LanguageSelectorCtrl: React.FC = () => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLanguage = lng => {
        i18next.changeLanguage(lng);
        window.location.reload();
    }

    // example: sometimes we get de-DE which is wrong for the flags
    const getFlagCountryCode = (lng: string) => {
        const _lng = lng.toLowerCase();

        if(_lng.startsWith("de")) return "de";
        if(_lng.startsWith("en")) return "en";

        return lng;
    }

    return (

        <div className="language-selector-ctrl">
            <span className="kt-link" onClick={handleClick}><CountryFlag countryCode={getFlagCountryCode(i18next.language)} width={15} /></span>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}><span onClick={() => changeLanguage("de")}><CountryFlag countryCode='de' width={15} /> Deutsch</span></MenuItem>
                <MenuItem onClick={handleClose}><span onClick={() => changeLanguage("en")}><CountryFlag countryCode='en' width={15} /> English</span></MenuItem>
                <MenuItem onClick={handleClose}><span onClick={() => changeLanguage("zh")}><CountryFlag countryCode='zh' width={15} /> 中文</span></MenuItem>
                <MenuItem onClick={handleClose}><span onClick={() => changeLanguage("el")}><CountryFlag countryCode='el' width={15} /> Ελληνικά</span></MenuItem>
                <MenuItem onClick={handleClose}><span onClick={() => changeLanguage("es")}><CountryFlag countryCode='es' width={15} /> Español</span></MenuItem>
                <MenuItem onClick={handleClose}><span onClick={() => changeLanguage("fr")}><CountryFlag countryCode='fr' width={15} /> Français</span></MenuItem>

            </Menu>
        </div>

    );
}

export default LanguageSelectorCtrl;